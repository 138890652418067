import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DropdownItem } from '@callrail/looky/ui';

import { UserModel } from '../../../models/user.model';

@Component({
  selector: 'core-users-multiselect-dropdown',
  templateUrl: './users-multiselect-dropdown.component.html',
})
export class UsersMultiselectDropdownComponent implements OnChanges {
  @Input() public users: UserModel[];
  @Output() public onSelectUser = new EventEmitter<DropdownItem[]>();

  public selectedUsers: DropdownItem[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.users) {
      this.selectedUsers = [];
    }
  }

  public updateSelection(selection: DropdownItem[]): void {
    this.onSelectUser.emit(selection);
  }
}
